import { useEffect } from "react";

import type { BankingEventType } from "../../types/bankingEvent";
import "./style.css";

interface FlinksProps {
  institutionId: string;
  onBack: () => void;
  onEvent: (e: BankingEventType) => void;
  onDone: () => void;
  flinksIframeURL: string;
}

function parseFlinksURLString(src: string, institutionId: string) {
  if (institutionId && institutionId.length > 0) {
    return `${src}/Credential/${institutionId}`;
  }
  return src;
}

function Flinks(props: FlinksProps) {
  useEffect(() => {
    const messageEventListener = (e: MessageEvent) => {
      let message = typeof e.data === "string" ? JSON.parse(e.data) : e.data;
      props.onEvent({
        aggregator: "flinks",
        data: { ...message },
        date: new Date().toISOString(),
      });
      if (message.step === "COMPONENT_LOAD_INSTITUTION_SELECTOR") props.onBack();
      if (!!message.loginId) props.onDone();
    };

    window.addEventListener("message", messageEventListener);

    return () => {
      window.removeEventListener("message", messageEventListener);
    };
  });

  useEffect(() => {
    window.parent.postMessage({ status: "branch", data: { eventName: "app_bank_login_flinks_loaded" } }, "*");
  });

  return (
    <div className="flinks-container">
      <iframe
        title="connect"
        className="flinks-connect"
        height="760"
        loading="lazy"
        src={parseFlinksURLString(props.flinksIframeURL, props.institutionId)}
      />
    </div>
  );
}

export default Flinks;
