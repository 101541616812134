export const ENV = {
  development: {
    REACT_APP_API_MODE: "staging",
    REACT_APP_PLAID_PUBLIC_KEY: "118a2b38698f30129ccae0ec782e2b",
    REACT_APP_PLAID_CLIENT_NAME: "iCash",
    REACT_APP_PLAID_ENVIRONMENT: "sandbox",
  },
  staging: {
    REACT_APP_API_MODE: "staging",
    REACT_APP_PLAID_PUBLIC_KEY: "118a2b38698f30129ccae0ec782e2b",
    REACT_APP_PLAID_CLIENT_NAME: "iCash",
    REACT_APP_PLAID_ENVIRONMENT: "development",
  },
  production: {
    REACT_APP_API_MODE: "prod",
    REACT_APP_PLAID_PUBLIC_KEY: "118a2b38698f30129ccae0ec782e2b",
    REACT_APP_PLAID_CLIENT_NAME: "iCash",
    REACT_APP_PLAID_ENVIRONMENT: "development",
  },
};
