import type { RedirectType, SearchRedirect, SelectRedirect } from "../../types/aggregation";

export function clickPlaidButton() {
  const els = document.getElementsByTagName("button");
  for (let i = 0; i < els.length; i++) {
    if (els[i].type === "button") {
      els[i].click();
      break;
    }
  }
}

function getRedirect(aggregatorRedirect: SearchRedirect | SelectRedirect | undefined): RedirectType | undefined {
  if (aggregatorRedirect) return aggregatorRedirect.redirect;
}

function redirectCallbackHandler(
  redirect: RedirectType | undefined,
  callback: (redirect: RedirectType) => void,
  fallback?: () => void
) {
  if (redirect) callback(redirect);
  else if (fallback) fallback();
}

export function findSearchRedirect(
  searchRedirects: SearchRedirect[],
  query: string | null,
  callback: (redirect: RedirectType) => void
) {
  redirectCallbackHandler(
    getRedirect(
      searchRedirects.find(
        (searchRedirectItem) =>
          searchRedirectItem.searchPrefix &&
          query?.toLowerCase().includes(searchRedirectItem.searchPrefix.toLowerCase())
      )
    ),
    callback
  );
}

export function findSelectRedirect(
  selectRedirects: SelectRedirect[],
  id: string | null,
  callback: (redirect: RedirectType) => void,
  fallback?: () => void
) {
  redirectCallbackHandler(
    getRedirect(selectRedirects.find((selectRedirectItem) => id === selectRedirectItem.plaidAggregatorInstitutionId)),
    callback,
    fallback
  );
}

export function getIsRedirect(
  searchRedirects: SearchRedirect[],
  selectRedirects: SelectRedirect[],
  institutionId: string | null,
  institutionName: string | null
) {
  let isSearchRedirect = false;
  let isSelectRedirect = false;

  findSearchRedirect(searchRedirects, institutionName, (_r) => {
    isSearchRedirect = true;
  });
  findSelectRedirect(selectRedirects, institutionId, (_r) => {
    isSelectRedirect = true;
  });

  return isSearchRedirect || isSelectRedirect;
}
