import { Fragment, useLayoutEffect, useState } from "react";
import { PlaidLink, PlaidLinkOnEvent, PlaidLinkOnExit, PlaidLinkOnSuccess } from "react-plaid-link";

import Popup from "../Popup";

import { clickPlaidButton, findSearchRedirect, findSelectRedirect, getIsRedirect } from "./helpers";

import type { RedirectType, SearchRedirect, SelectRedirect } from "../../types/aggregation";
import type { BankingEventType } from "../../types/bankingEvent";

import "./style.css";
interface PlaidProps {
  clientName: string;
  env: string;
  onDone: (isRedirect?: boolean) => void;
  onEvent: (e: BankingEventType) => void;
  onRedirect: (id: string) => void;
  plaidInstitutionSearchRedirects: SearchRedirect[];
  plaidInstitutionSelectRedirects: SelectRedirect[];
  publicKey: string;
  sessionId: string;
}

function Plaid(props: PlaidProps) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const [searchInstitutionName, setSearchInstitutionName] = useState<string>();
  const [institutionId, setInstitutionId] = useState<string>();

  const handleSearchEventRedirect = (redirect: RedirectType) => {
    setInstitutionId(redirect.aggregatorInstitutionId);
    setSearchInstitutionName(redirect.aggregatorInstitutionName);
  };

  const handleSelectEventRedirect = (redirect: RedirectType) => {
    setInstitutionId(redirect.aggregatorInstitutionId);
  };

  const handleSelectEvent = () => {
    window.parent.postMessage({ status: "branch", data: { eventName: "app_bank_login_plaid_loaded" } }, "*");
  };

  const handleEvent: PlaidLinkOnEvent = (e, m) => {
    props.onEvent({
      aggregator: "plaid",
      data: { eventName: e, ...m },
      date: new Date().toISOString(),
    });

    switch (e) {
      case "SEARCH_INSTITUTION":
        findSearchRedirect(
          props.plaidInstitutionSearchRedirects,
          m.institution_search_query,
          handleSearchEventRedirect
        );
        break;
      case "SELECT_INSTITUTION":
        findSelectRedirect(
          props.plaidInstitutionSelectRedirects,
          m.institution_id,
          handleSelectEventRedirect,
          handleSelectEvent
        );
        break;
      case "EXIT":
        let isRedirect = false;
        if (m.exit_status === "requires_credentials") {
          isRedirect = getIsRedirect(
            props.plaidInstitutionSearchRedirects,
            props.plaidInstitutionSelectRedirects,
            m.institution_id,
            m.institution_name
          );
        }
        if (!isRedirect) props.onDone(true);
        break;
      default:
        break;
    }
  };

  const handleExit: PlaidLinkOnExit = (e, m) => {
    if (e)
      props.onEvent({
        aggregator: "plaid",
        data: { eventName: "EXIT", error: e, ...m },
        date: new Date().toISOString(),
      });
  };

  const handleLoad = () => {
    props.onEvent({
      aggregator: "plaid",
      data: { eventName: "LOAD" },
      date: new Date().toISOString(),
    });
    setHasLoaded(true);
    clickPlaidButton();
  };

  const handleSuccess: PlaidLinkOnSuccess = (_token, message) => {
    props.onEvent({
      aggregator: "plaid",
      data: { eventName: "SUCCESS", ...message },
      date: new Date().toISOString(),
    });
    props.onDone();
  };

  const handlePopupCancel = () => {
    setInstitutionId(undefined);
    setSearchInstitutionName(undefined);
    setHasLoaded(false);
    setIsReloading(true);
  };

  const handlePopupConfirm = () => {
    if (institutionId) {
      props.onRedirect(institutionId);
    }
  };

  useLayoutEffect(() => {
    if (hasLoaded) {
      let plaidLinkRoot = document.getElementById("root");
      let plaidContainer = document.getElementById("plaid-container");
      let plaidIframe = document.getElementsByTagName("iframe")[0];
      plaidIframe.style.zIndex = "1";
      plaidIframe.style.position = "absolute";
      plaidIframe.style.height = "100%";
      plaidIframe.style.width = "100%";

      plaidContainer?.appendChild(plaidIframe);

      if (plaidLinkRoot) {
        plaidLinkRoot.style.setProperty("display", "block", "important"); // When application come from mobile, there is a display: none !important set by react-plaid-link. We reset to display: block.
      }
    }
  }, [hasLoaded]);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      if (isReloading) {
        setIsReloading(false);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [isReloading]);

  return (
    <Fragment>
      <div id="plaid-container" className="relative w-full h-screen" />
      <div className="plaid-loading">{hasLoaded ? "Thank you" : "Loading"}</div>
      {!isReloading && (
        <PlaidLink
          clientName={props.clientName}
          env={props.env}
          product={["auth", "assets"]}
          countryCodes={["CA"]}
          publicKey={props.publicKey}
          webhook={"https://alicia-public-sajj3ulrsa-nn.a.run.app/v1/webhooks/plaid/item"}
          onEvent={handleEvent}
          onExit={handleExit}
          onLoad={handleLoad}
          onSuccess={handleSuccess}
        >
          Open Link and connect to your bank!
        </PlaidLink>
      )}
      {institutionId && (
        <Popup onCancel={handlePopupCancel} onConfirm={handlePopupConfirm}>
          {searchInstitutionName ? `Are you looking for ${searchInstitutionName}?` : `You are about to leave Plaid.`}
          <br />
          <br />
          Accessing this institution will require leaving Plaid, are you ready?
        </Popup>
      )}
    </Fragment>
  );
}

export default Plaid;
