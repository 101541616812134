import { createContext, Dispatch, useReducer } from "react";

type RequestsStateType = {
  token?: string;
  applicationId?: string;
  loading?: boolean;
};

type RequestsActionType = {
  type: "setInfo";
  payload: {
    token?: string;
    applicationId?: string;
  };
};

const fullaReducer = (state: RequestsStateType, action: RequestsActionType) => {
  switch (action.type) {
    case "setInfo":
      return {
        ...state,
        applicationId: action.payload.applicationId,
        token: action.payload.token,
        loading: false,
      };
    default:
      return state;
  }
};

export const useFullaReducer = () => useReducer(fullaReducer, {}, () => ({}));

export const FullaContext = createContext<{
  state: RequestsStateType;
  dispatch: Dispatch<RequestsActionType>;
}>({
  state: {},
  dispatch: (_action) => {},
});
