import { useEffect, useReducer, useState, useContext } from "react";
import "./style.css";

import Flinks from "../Flinks";

import Plaid from "../Plaid";

import { bankingEventsReducer, initialBankingEventsState } from "../../reducers/events";

import { createBankingSession, postError, postSessionData, postSessionEvents } from "./helpers";

import type { AggregaterDataType } from "../../types/aggregation";
import type { BankingEventType } from "../../types/bankingEvent";
import { FullaContext } from "../../reducers/fulla";
import { ENV } from "../../env-data";

const config = {
  plaid: {
    publicKey: ENV[process.env.REACT_APP_ENVIRONMENT].REACT_APP_PLAID_PUBLIC_KEY,
    clientName: ENV[process.env.REACT_APP_ENVIRONMENT].REACT_APP_PLAID_CLIENT_NAME,
    env: ENV[process.env.REACT_APP_ENVIRONMENT].REACT_APP_PLAID_ENVIRONMENT,
  },
  flinks: {
    flinksIframeURL: "https://techbanxdev-iframe.private.fin.ag",
  },
};

function Banking() {
  const [aggregationData, setAggregationData] = useState<AggregaterDataType>();
  const [flinksInstitutionId, setFlinksInstitutionId] = useState<string | undefined>();
  const [isDone, setIsDone] = useState<boolean>(false);

  const [eventsState, eventDispatch] = useReducer(bankingEventsReducer, initialBankingEventsState);

  const handleFlinksBack = () => setFlinksInstitutionId(undefined);

  const handlePlaidRedirect = (id?: string) => setFlinksInstitutionId(id);

  const { state } = useContext(FullaContext);

  const handleEvent = (e: BankingEventType) => {
    eventDispatch(e);
  };

  const handleDone = (isRedirect?: boolean) => {
    if (isRedirect === true) {
      window.parent.postMessage(
        {
          status: "redirect",
          data: {
            action: "cross",
          },
        },
        "*"
      );
    } else {
      window.parent.postMessage(
        {
          status: "branch",
          data: {
            eventName: flinksInstitutionId
              ? "app_flinks_bank_login_success_loaded"
              : "app_plaid_bank_login_success_loaded",
          },
        },
        "*"
      );
      setIsDone(true);
    }
  };

  const onCreateAggregationData = (newAggregaterData: AggregaterDataType) => {
    window.parent.postMessage(
      {
        status: "new",
        data: {
          sessionId: newAggregaterData.sessionId,
        },
      },
      "*"
    );
    setAggregationData(newAggregaterData);
  };

  const onFetchAggregateData = async () => {
    try {
      const data = await createBankingSession(state.token ?? "", state.applicationId ?? "");
      onCreateAggregationData(data);
    } catch (error) {
      postError(error);
    }
  };

  useEffect(() => {
    if (!aggregationData) {
      window.parent.postMessage({ status: "branch", data: { eventName: "app_pre_bank_selection_submitted" } }, "*");
      onFetchAggregateData();
    }
    // eslint-disable-next-line
  }, [aggregationData]);

  const onFetchSessionData = async (sessionId: string) => {
    try {
      await postSessionEvents(state.token ?? "", state.applicationId ?? "", sessionId, eventsState);
      await postSessionData(
        state.token ?? "",
        state.applicationId ?? "",
        flinksInstitutionId ? "flinks" : "plaid",
        sessionId,
        eventsState,
        flinksInstitutionId
      );
      window.parent.postMessage({ status: "success", data: { sessionId: sessionId } }, "*");
    } catch (error) {
      postError(error);
    }
  };

  useEffect(() => {
    if (isDone && !!aggregationData) {
      onFetchSessionData(aggregationData.sessionId);
    }
    // eslint-disable-next-line
  }, [isDone, aggregationData]);

  return (
    <>
      {flinksInstitutionId && (
        <div className="iframe-container">
          <Flinks
            {...config.flinks}
            institutionId={flinksInstitutionId}
            onBack={handleFlinksBack}
            onEvent={handleEvent}
            onDone={handleDone}
          />
        </div>
      )}
      {aggregationData && (
        <div className="iframe-container">
          <Plaid
            {...config.plaid}
            {...aggregationData}
            onEvent={handleEvent}
            onDone={handleDone}
            onRedirect={handlePlaidRedirect}
          />
        </div>
      )}
    </>
  );
}

export default Banking;
