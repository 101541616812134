import { PropsWithChildren } from "react";
import "./style.css";

interface PopupProps {
  onCancel: () => void;
  onConfirm: () => void;
}

function Popup(props: PropsWithChildren<PopupProps>) {
  return (
    <div className="popup-container">
      <div className="popup">
        {props.children}
        <div className="popup-button-container">
          <button onClick={props.onCancel}>Cancel</button>
          <button onClick={props.onConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
}

export default Popup;
