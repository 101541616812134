import { useEffect } from "react";

import { RequestMessage } from "../../types/message";
import { FullaContext, useFullaReducer } from "../../reducers/fulla";
import { Loader } from "../Loader";
import { Route, Routes, useNavigate } from "react-router-dom";
import Banking from "../Banking";

function App() {
  const [requestsState, requestsDispatch] = useFullaReducer();
  const navigate = useNavigate();

  useEffect(() => {
    const authEventListener = (e: MessageEvent) => {
      try {
        let message: RequestMessage | undefined = typeof e.data === "string" ? JSON.parse(e.data) : e.data;

        if (message)
          if (message.status === "info") {
            requestsDispatch({
              type: "setInfo",
              payload: {
                ...message.data,
              },
            });
          } else if (message.status === "reload") {
            navigate(0);
          }
      } catch (error) {}
    };
    window.addEventListener("message", authEventListener);

    return () => {
      window.removeEventListener("message", authEventListener);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.parent.postMessage(
      {
        status: "ready",
        data: {
          message: "Component Ready.",
        },
      },
      "*"
    );

    return () => {};
  }, []);

  return (
    <FullaContext.Provider value={{ state: requestsState, dispatch: requestsDispatch }}>
      <Loader loading={requestsState.loading === true || requestsState.loading === undefined}>
        <Routes>
          <Route path={""} element={<Banking />} />
        </Routes>
      </Loader>
    </FullaContext.Provider>
  );
}

export default App;
