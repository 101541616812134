import { ENV } from "../../env-data";
import type { AggregaterDataType } from "../../types/aggregation";
import { BankingEventsStateType } from "../../types/bankingEvent";
import { FlinksSessionDataType, PlaidSessionDataType } from "../../types/sessionData";

export class ApiError extends Error {
  constructor(msg: string, public code: number) {
    super(msg);

    this.code = code;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ApiError.prototype);
  }

  public getMessage() {
    return this.message;
  }

  public getCode() {
    return this.code;
  }
}

const getUrl = () => {
  if (ENV[process.env.REACT_APP_ENVIRONMENT].REACT_APP_API_MODE === "localhost") {
    return "http://localhost:8080/api";
  } else if (ENV[process.env.REACT_APP_ENVIRONMENT].REACT_APP_API_MODE === "mock") {
    return "https://527dee03-b832-4a0d-8451-19d4be4ac965.mock.pstmn.io";
  } else if (ENV[process.env.REACT_APP_ENVIRONMENT].REACT_APP_API_MODE === "staging") {
    return "https://preprod-maria.finabanx.com/api";
  } else if (ENV[process.env.REACT_APP_ENVIRONMENT].REACT_APP_API_MODE === "prod") {
    return "https://maria.finabanx.com/api";
  }
};

const url = getUrl();

console.log("url 6: ", url);

const createHeaders = (token: string, applicationId: string) => {
  return new Headers({
    "content-type": "application/json",
    Authorization: `Bearer ${token}`,
    "Application-ID": applicationId,
  });
};

export function postError(error: any) {
  if (error instanceof ApiError) {
    window.parent.postMessage(
      {
        status: "error",
        data: {
          code: error.code,
          message: error.message,
        },
      },
      "*"
    );
  } else {
    window.parent.postMessage(
      {
        status: "error",
        data: {
          code: error.code ?? 1,
          message: error.message ?? "",
        },
      },
      "*"
    );
  }
}

export async function createBankingSession(token: string, applicationId: string): Promise<AggregaterDataType> {
  const response = await fetch(`${url}/banking-session`, {
    method: "POST",
    headers: createHeaders(token, applicationId),
  });
  const data = await response.json();

  if (data.error) {
    throw new ApiError(data.error.message, data.error.code);
  }

  return data.result;
}

export async function postSessionEvents(
  token: string,
  applicationId: string,
  sessionId: string,
  eventsState: BankingEventsStateType
) {
  await fetch(`${url}/banking-session/${sessionId}/events`, {
    method: "POST",
    headers: createHeaders(token, applicationId),
    body: JSON.stringify(Object.values(eventsState)),
  });
}

async function findFlinksSessionData(
  eventsState: BankingEventsStateType,
  flinksInstitutionId: string
): Promise<FlinksSessionDataType> {
  const flinksSuccessEvent = eventsState.find((e) => e.aggregator === "flinks" && e.data.loginId);
  if (!flinksSuccessEvent) throw Error("Session Data not found.");
  const { loginId, requestId } = flinksSuccessEvent.data as FlinksSessionDataType;
  return { institutionId: flinksInstitutionId, loginId, requestId };
}

async function findPlaidSessionData(eventsState: BankingEventsStateType): Promise<PlaidSessionDataType> {
  const plaidSuccessEvent = eventsState.find((e) => e.aggregator === "plaid" && e.data.eventName === "SUCCESS");

  if (!plaidSuccessEvent) throw Error("Session Data not found.");
  const { institution, link_session_id: linkSessionId, public_token: publicToken } = plaidSuccessEvent.data;
  return { institutionId: institution.institution_id, linkSessionId, publicToken };
}

async function postFlinksSessionData(
  token: string,
  applicationId: string,
  sessionId: string,
  flinksSessionData: FlinksSessionDataType
) {
  const response = await fetch(`${url}/banking-session/${sessionId}/data/flinks`, {
    method: "POST",
    headers: createHeaders(token, applicationId),
    body: JSON.stringify(Object.values(flinksSessionData)),
  });

  if (response.status === 201) {
    return;
  }

  const data = await response.json();

  if (data.error) {
    throw new ApiError(data.error.message, data.error.code);
  }
}

async function postPlaidSessionData(
  token: string,
  applicationId: string,
  sessionId: string,
  plaidSessionData: PlaidSessionDataType
) {
  const response = await fetch(`${url}/banking-session/${sessionId}/data/plaid`, {
    method: "POST",
    headers: createHeaders(token, applicationId),
    body: JSON.stringify(plaidSessionData),
  });

  if (response.status === 201) {
    return;
  }
  const data = await response.json();

  if (data.error) {
    throw new ApiError(data.error.message, data.error.code);
  }
}

export async function postSessionData(
  token: string,
  applicationId: string,
  aggregator: "plaid" | "flinks",
  sessionId: string,
  eventsState: BankingEventsStateType,
  flinksInstitutionId?: string
) {
  if (aggregator === "flinks" && flinksInstitutionId) {
    const flinksSessionData = await findFlinksSessionData(eventsState, flinksInstitutionId);
    await postFlinksSessionData(token, applicationId, sessionId, flinksSessionData);
  } else {
    const plaidSessionData = await findPlaidSessionData(eventsState);
    await postPlaidSessionData(token, applicationId, sessionId, plaidSessionData);
  }
}
